<template>
    <div>
        <div class="container">
            <h1 class="text-center fs-xs-30 px-xs-4">Detective privado no Centro de Portugal?</h1>
            <article class="col-lg-11 px-0 mx-auto fs-sm-17 text-center mt-4 fw-500">
                Precisando de <b>Detective Privado para sua Empresa</b> em <b>Portugal?</b>
                A ABRAIP Detectives está em <b>Portugal</b> para poder lhe ajudar.
                Somos especializados em <b>casos empresarias, contra inteligência empresarial, varredura inteligente e verificação da conduta de sócios e/ou funcionários,</b> sempre executando nossos serviços de forma rápida, discreta e sigilosa, ajustados de acordo com a necessidade, exigência e orçamento de cada cliente. Não importa a complexidade do seu caso, nossos detectives estão preparados para atender sua demanda de forma <b>eficaz e profissional,</b> proporcionando assim o esclarecimento de suas dúvidas, a satisfação e a paz de espírito que você necessita e merece.
            </article>
            <div class="d-flex justify-content-center row mx-auto mt-30">
                <BtnLigueAgora class="col-xs-10" />
                <BtnFaleNoWhatsapp class="mt-xs-2 col-xs-10 ml-sm-3" />
            </div>
        </div>
        <section class="pt-80">
            <div class="container">
                <h3 class="text-uppercase fs-xs-25 fw-600 text-center text-dark">
                  Encontre um detetive na sua cidade
                </h3>
                <div v-for="(letra, index) in cidadesPorLetra" :key="index" class="position-relative overflow-hidden mt-40 col-12 d-flex row mx-auto pr-0 pt-1 pb-30 pl-45">
                    <div class="d-flex div-bolinha-letra-cidade justify-content-center align-items-center">
                        <h5 class="text-dark fw-600 mb-0">
                            {{ letra[0].nome.charAt(0).toUpperCase() }}
                        </h5>
                    </div>
                    <div class="borda-top-preto-separation"></div>
                    <div v-for="n in 3" :key="n" class="col-lg px-0 mt-4">
                        <a v-for="(cidade, i) in colunaCidades(letra, n)" :key="i" @click.prevent="navigateToCity(cidade.rota)" class="d-flex text-preto-cidades fs-sm-17 fw-600 btn align-items-center">
                            <i class="fa fa-angle-right fw-300 fs-25 mr-1"></i> {{ cidade.nome }}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import BtnLigueAgora from '../../../buttons/btn-outline-ligue-agora'
import BtnFaleNoWhatsapp from '../../../buttons/btn-outline-fale-no-whatsapp'

export default {
  components: {
    BtnLigueAgora,
    BtnFaleNoWhatsapp
  },
  data () {
    return {
      cidadesPorLetra: [
        [{ nome: 'Alcochete', rota: 'alcochete' }, { nome: 'Almada', rota: 'almada' }, { nome: 'Amadora', rota: 'amadora' }, { nome: 'Angra do Heroísmo', rota: 'angra-do-heroísmo' }, { nome: 'Abrantes', rota: 'abrantes' }, { nome: 'Aguiar da Beira', rota: 'aguiar-da-beira' }, { nome: 'Albergaria-a-Velha', rota: 'albergaria-a-velha' }, { nome: 'Alcanena', rota: 'alcanena' }, { nome: 'Alcobaça', rota: 'alcobaça' }, { nome: 'Alenquer', rota: 'alenquer' }, { nome: 'Almeida', rota: 'almeida' }, { nome: 'Alvaiázere', rota: 'alvaiázere' }, { nome: 'Anadia', rota: 'anadia' }, { nome: 'Ansião', rota: 'ansião' }, { nome: 'Arganil', rota: 'arganil' }, { nome: 'Arruda dos Vinhos', rota: 'arruda-dos-vinhos' }, { nome: 'Aveiro', rota: 'aveiro' }],
        [{ nome: 'Barreiro', rota: 'barreiro' }],
        [{ nome: 'Cascais', rota: 'cascais' }, { nome: 'Calheta', rota: 'calheta' }, { nome: 'Corvo', rota: 'corvo' }, { nome: 'Batalha', rota: 'batalha' }, { nome: 'Belmonte', rota: 'belmonte' }, { nome: 'Bombarral', rota: 'bombarral' }],
        [{ nome: 'Cadaval', rota: 'cadaval' }, { nome: 'Caldas da Rainha', rota: 'caldas-da-rainha' }, { nome: 'Cantanhede', rota: 'cantanhede' }, { nome: 'Carregal do Sal', rota: 'carregal-do-sal' }, { nome: 'Castanheira de Pêra', rota: 'castanheira-de-pêra' }, { nome: 'Castelo Branco', rota: 'castelo-branco' }, { nome: 'Castro Daire', rota: 'castro-daire' }, { nome: 'Celorico da Beira', rota: 'celorico-da-beira' }, { nome: 'Coimbra', rota: 'coimbra' }, { nome: 'Condeixa-a-Nova', rota: 'condeixa-a-nova' }, { nome: 'Constância', rota: 'constância' }, { nome: 'Covilhã', rota: 'covilhã' }],
        [{ nome: 'Entroncamento', rota: 'entroncamento' }, { nome: 'Estarreja', rota: 'estarreja' }],
        [{ nome: 'Ferreira do Zêzere', rota: 'ferreira-do-zêzere' }, { nome: 'Figueira da Foz', rota: 'figueira-da-foz' }, { nome: 'Figueira de Castelo Rodrigo', rota: 'figueira-de-castelo-rodrigo' }, { nome: 'Figueiró dos Vinhos', rota: 'figueiró-dos-vinhos' }, { nome: 'Fornos de Algodres', rota: 'fornos-de-algodres' }, { nome: 'Fundão', rota: 'fundão' }],
        [{ nome: 'Gouveia', rota: 'gouveia' }, { nome: 'Guarda', rota: 'guarda' }, { nome: 'Góis', rota: 'góis' }],
        [{ nome: 'Horta', rota: 'horta' }],
        [{ nome: 'Idanha-a-Nova', rota: 'idanha-a-nova' }],
        [{ nome: 'Lisboa', rota: 'lisboa' }, { nome: 'Loures', rota: 'loures' }, { nome: 'Lagoa', rota: 'lagoa' }, { nome: 'Lajes das Flores', rota: 'lajes-das-flores' }, { nome: 'Lajes do Pico', rota: 'lajes-do-pico' }, { nome: 'Leiria', rota: 'leiria' }, { nome: 'Lourinhã', rota: 'lourinhã' }, { nome: 'Lousã', rota: 'lousã' }],
        [{ nome: 'Mafra', rota: 'mafra' }, { nome: 'Moita', rota: 'moita' }, { nome: 'Montijo', rota: 'montijo' }, { nome: 'Madalena', rota: 'madalena' }, { nome: 'Mangualde', rota: 'mangualde' }, { nome: 'Manteigas', rota: 'manteigas' }, { nome: 'Marinha Grande', rota: 'marinha-grande' }, { nome: 'Mação', rota: 'mação' }, { nome: 'Mealhada', rota: 'mealhada' }, { nome: 'Mira', rota: 'mira' }, { nome: 'Miranda do Corvo', rota: 'miranda-do-corvo' }, { nome: 'Montemor-o-Velho', rota: 'montemor-o-velho' }, { nome: 'Mortágua', rota: 'mortágua' }, { nome: 'Murtosa', rota: 'murtosa' }, { nome: 'Mêda', rota: 'mêda' }],
        [{ nome: 'Nordeste', rota: 'nordeste' }, { nome: 'Nazaré', rota: 'nazaré' }, { nome: 'Nelas', rota: 'nelas' }],
        [{ nome: 'Odivelas', rota: 'odivelas' }, { nome: 'Oeiras', rota: 'oeiras' }, { nome: 'Oleiros', rota: 'oleiros' }, { nome: 'Oliveira de Frades', rota: 'oliveira-de-frades' }, { nome: 'Oliveira do Bairro', rota: 'oliveira-do-bairro' }, { nome: 'Oliveira do Hospital', rota: 'oliveira-do-hospital' }, { nome: 'Ourém', rota: 'ourém' }, { nome: 'Ovar', rota: 'ovar' }],
        [{ nome: 'Palmela', rota: 'palmela' }, { nome: 'Ponta Delgada', rota: 'ponta-delgada' }, { nome: 'Povoação', rota: 'povoação' }, { nome: 'Praia da Vitória', rota: 'praia-da-vitória' }, { nome: 'Pampilhosa da Serra', rota: 'pampilhosa-da-serra' }, { nome: 'Pedrógão Grande', rota: 'pedrógão-grande' }, { nome: 'Penacova', rota: 'penacova' }, { nome: 'Penalva do Castelo', rota: 'penalva-do-castelo' }, { nome: 'Penamacor', rota: 'penamacor' }, { nome: 'Penela', rota: 'penela' }, { nome: 'Peniche', rota: 'peniche' }, { nome: 'Pinhel', rota: 'pinhel' }, { nome: 'Pombal', rota: 'pombal' }, { nome: 'Porto de Mós', rota: 'porto-de-mós' }, { nome: 'Proença-a-Nova', rota: 'proença-a-nova' }],
        [{ nome: 'Ribeira Grande', rota: 'ribeira-grande' }],
        [{ nome: 'Seixal', rota: 'seixal' }, { nome: 'Sesimbra', rota: 'sesimbra' }, { nome: 'Setúbal', rota: 'setúbal' }, { nome: 'Sintra', rota: 'sintra' }, { nome: 'Santa Cruz da Graciosa', rota: 'santa-cruz-da-graciosa' }, { nome: 'Santa Cruz das Flores', rota: 'santa-cruz-das-flores' }, { nome: 'São Roque do Pico', rota: 'são-roque-do-pico' }, { nome: 'Sabugal', rota: 'sabugal' }, { nome: 'Santa Comba Dão', rota: 'santa-comba-dão' }, { nome: 'Sardoal', rota: 'sardoal' }, { nome: 'Seia', rota: 'seia' }, { nome: 'Sertã', rota: 'sertã' }, { nome: 'Sever do Vouga', rota: 'sever-do-vouga' }, { nome: 'Sobral de Monte Agraço', rota: 'sobral-de-monte-agraço' }, { nome: 'Soure', rota: 'soure' }, { nome: 'Sátão', rota: 'sátão' }, { nome: 'São Pedro do Sul', rota: 'são-pedro-do-sul' }],
        [{ nome: 'Tomar', rota: 'tomar' }, { nome: 'Tondela', rota: 'tondela' }, { nome: 'Torres Novas', rota: 'torres-novas' }, { nome: 'Torres Vedras', rota: 'torres-vedras' }, { nome: 'Trancoso', rota: 'trancoso' }, { nome: 'Tábua', rota: 'tábua' }],
        [{ nome: 'Vila Franca de Xira', rota: 'vila-franca-de-xira' }, { nome: 'Velas', rota: 'velas' }, { nome: 'Vila Franca do Campo', rota: 'vila-franca-do-campo' }, { nome: 'Vila do Porto', rota: 'vila-do-porto' }, { nome: 'Vagos', rota: 'vagos' }, { nome: 'Vila Nova da Barquinha', rota: 'vila-nova-da-barquinha' }, { nome: 'Vila Nova de Paiva', rota: 'vila-nova-de-paiva' }, { nome: 'Vila Nova de Poiares', rota: 'vila-nova-de-poiares' }, { nome: 'Vila Velha de Ródão', rota: 'vila-velha-de-ródão' }, { nome: 'Vila de Rei', rota: 'vila-de-rei' }, { nome: 'Viseu', rota: 'viseu' }, { nome: 'Vouzela', rota: 'vouzela' }],
        [{ nome: 'Águeda', rota: 'Águeda' }],
        [{ nome: 'Ílhavo', rota: 'Ílhavo' }],
        [{ nome: 'Óbidos', rota: 'Óbidos' }]
        // Continue formatting other subarrays similarly
      ]
    }
  },
  methods: {
    navigateToCity (rota) {
      this.$router.push(`/detective-privado-no-centro-de-portugal/detective-privado-em-${rota}`)
      this.$smoothScroll({
        scrollTo: document.getElementById('sobre'),
        duration: 500,
        offset: -120
      })
    },
    colunaCidades (grupo, n) {
      const tamanhoColuna = Math.ceil(grupo.length / 3)
      const inicio = (n - 1) * tamanhoColuna
      const fim = inicio + tamanhoColuna
      return grupo.slice(inicio, fim)
    }
  },
  metaInfo: {
    htmlAttrs: {
      lang: 'pt-BR'
    },
    title: 'Detective Privado em Norte | Braga | Barcelos | Felgueiras | Guimarães | Maia | Matosinhos | Santo Tirso',
    meta: [{ name: 'description', content: 'Somos uma agência de detectives focada em casos empresarias, contra inteligência empresarial, varredura inteligente, verificação da conduta de sócios e/ou funcionários e muito mais!' }]
  }
}
</script>

<style lang="scss" scoped>
/* Adicione seus estilos aqui */
</style>
